<template>
    <div class="app-footer-container">
        <footer>
            <p class="app-footer-copyright font-montserrat">{{appTitle}} © {{new Date().getFullYear()}}</p>
        </footer>
    </div>
</template>

<script>
export default {
  name: 'AppFooter',
  data(){
      return{
          appTitle: document.title
      }
  },
  setup(){ }
}
</script>

<style scoped>
.app-footer-container {
    padding:40px 0;
    color:#4b4c4d;
}
.app-footer-container .app-footer-copyright {
    margin-top:15px;
    text-align:center;
    font-size:18px;
    color:#aaa;
    margin-bottom:0;
}
</style>