<template>
    <div :id="id" class="app-section-car-type-container p-2 mt-5 mb-5">
        <div class="section-divider"></div>
        <div class="app-section-car-type-inner-container">
            <SectionTitle 
                title="Encuentra tu estilo" 
                subtitle="Elegancia, seguridad y calidad"
            />
            <div class="row mt-5">
                <div class="col-lg-12">
                    <div class="app-section-car-type-card-container mt-4 mb-4">
                        <div class="app-section-car-type-card-inner-container app-section-car-type-card-inner-container-sedan">
                            <p class="app-section-car-type-card-title app-section-car-type-card-title-sedan font-ptsans">SEDAN</p>
                            <p class="app-section-car-type-card-desc font-montserrat">
                                Descubre la calidad y ahorro de nuestros modelos.
                            </p>
                            <a 
                                href="#sedanBrandListSection"
                                class="app-section-car-type-card-button-model app-section-car-type-card-button-model-sedan font-montserrat"
                                @click="showSedanCarBrandList()">
                                {{carBrandListProps.sedanType.buttonText}}
                            </a>
                            <img src="../assets/svg/sedan.svg" alt="Sedan">
                        </div>
                        <SectionCarTypeBrandList
                            id="sedanBrandListSection"
                            :animate="showSedanBrandList"
                            :inAnimation="carBrandListProps.sedanType.inAnimation"
                            :classContainer="carBrandListProps.sedanType.classContainer"
                            :payload="{
                                title: 'Listado por marca',
                                subtitle: 'Sedan',
                                data: carBrandListProps.sedanType.data
                            }"
                            v-if="showSedanBrandList"
                        />
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="app-section-car-type-card-container mt-4 mb-4">
                        <div class="app-section-car-type-card-inner-container app-section-car-type-card-inner-container-suv">
                            <p class="app-section-car-type-card-title app-section-car-type-card-title-suv font-ptsans">SUV</p>
                            <p class="app-section-car-type-card-desc font-montserrat">
                                Experimenta potencia y tecnología en tu camino.
                            </p>
                            <a 
                                href="#suvBrandListSection"
                                class="app-section-car-type-card-button-model app-section-car-type-card-button-model-suv font-montserrat"
                                @click="showSuvCarBrandList()">
                                {{carBrandListProps.suvType.buttonText}}
                            </a>
                            <img src="../assets/svg/suv.svg" alt="SUV">
                        </div>
                        <SectionCarTypeBrandList
                            id="suvBrandListSection"
                            :animate="showSuvBrandList"
                            :inAnimation="carBrandListProps.suvType.inAnimation"
                            :classContainer="carBrandListProps.suvType.classContainer"
                            :payload="{
                                title: 'Listado por marca',
                                subtitle: 'Sedan',
                                data: carBrandListProps.suvType.data
                            }"
                            v-if="showSuvBrandList"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SectionTitle from './app/SectionTitle.vue';
import SectionCarTypeBrandList from './CarTypeBrandList.vue';
import { animateCSS } from '../assets/js/utilities.js';

export default {
    name: 'SectionCarType',
    components: {
        SectionTitle,
        SectionCarTypeBrandList
    },
    props: {
        id: String
    },
    data(){
        return{
            showSedanBrandList: false,
            showSuvBrandList: false,
            carBrandListProps:{
                sedanType: {
                    buttonText: "Ver modelos",
                    classContainer: "app-section-car-type-brand-list-g-container",
                    inAnimation: "fadeIn",
                    outAnimation: "fadeOut",
                    data: [
                        {
                            brandName: "Kia",
                            brandSlogan: "The Power to Surprise",
                            brandIcon: require("@/assets/img/cars/brand/kia.svg"),
                            brandIconColor: "#eaedf1",
                            modelList: [
                                {
                                    id: `uuid${(Math.random() + 1).toString(36).substring(7)}`,
                                    icon: require("@/assets/img/cars/model/kia/forte0001.jpeg"),
                                    feature: [
                                        {text: "Modelo", value: "Forte", icon: "fas fa-car"},
                                        {text: "Año", value: "2017", icon: "fas fa-calendar"},
                                        {text: "Color", value: "Gris", icon: "fas fa-palette"},
                                        {text: "Cilindraje", value: "2,000cc", icon: "fas fa-car-side"},
                                        {text: "Potencia", value: "150 hp", icon: "fas fa-cogs"},
                                        {text: "Transmisión", value: "Automática", icon: "fas fa-arrows-alt-v"}
                                    ],
                                    galleryData: this.populateMedia("assets/img/cars/model/kia/forte/2017/1/forte", 8)
                                },
                                {
                                    id: `uuid${(Math.random() + 1).toString(36).substring(7)}`,
                                    icon: require("@/assets/img/cars/model/kia/forte0002.jpeg"),
                                    feature: [
                                        {text: "Modelo", value: "Forte", icon: "fas fa-car"},
                                        {text: "Año", value: "2018", icon: "fas fa-calendar"},
                                        {text: "Color", value: "Gris", icon: "fas fa-palette"},
                                        {text: "Cilindraje", value: "2,000cc", icon: "fas fa-car-side"},
                                        {text: "Potencia", value: "150 hp", icon: "fas fa-cogs"},
                                        {text: "Transmisión", value: "Automática", icon: "fas fa-arrows-alt-v"}
                                    ],
                                    galleryData: this.populateMedia("assets/img/cars/model/kia/forte/2017/2/forte", 8)
                                }
                            ]
                        },
                        /*{
                            brandName: "Hyundai",
                            brandSlogan: "New Thinking – New Possibilities",
                            brandIcon: require("@/assets/img/cars/brand/hyundai.svg"),
                            brandIconColor: "#e5eaf2",
                            modelList: [
                                {
                                    id: `uuid${(Math.random() + 1).toString(36).substring(7)}`,
                                    icon: require("@/assets/img/cars/model/hyundai/elantra001.jpg"),
                                    feature: [
                                        {text: "Modelo", value: "Elantra", icon: "fas fa-car"},
                                        {text: "Año", value: "2019", icon: "fas fa-calendar"},
                                        {text: "Color", value: "Negro", icon: "fas fa-palette"},
                                        {text: "Cilindraje", value: "2,000cc", icon: "fas fa-car-side"},
                                        {text: "Potencia", value: "147 hp", icon: "fas fa-cogs"},
                                        {text: "Transmisión", value: "Automática", icon: "fas fa-arrows-alt-v"}
                                    ],
                                    galleryData: this.populateMedia("assets/img/cars/model/hyundai/elantra/2019/elantra", 9)
                                }
                            ]
                        },*/
                        {
                            brandName: "Nissan",
                            brandSlogan: "Innovation that excites",
                            brandIcon: require("@/assets/img/cars/brand/nissan.svg"),
                            brandIconColor: "#F7F7F7",
                            modelList: [
                                {
                                    id: `uuid${(Math.random() + 1).toString(36).substring(7)}`,
                                    icon: require("@/assets/img/cars/model/nissan/sentra0.jpeg"),
                                    feature: [
                                        {text: "Modelo", value: "Sentra", icon: "fas fa-car"},
                                        {text: "Año", value: "2018", icon: "fas fa-calendar"},
                                        {text: "Color", value: "Azul", icon: "fas fa-palette"},
                                        {text: "Cilindraje", value: "1,800cc", icon: "fas fa-car-side"},
                                        {text: "Potencia", value: "130 hp", icon: "fas fa-cogs"},
                                        {text: "Transmisión", value: "Automática", icon: "fas fa-arrows-alt-v"}
                                    ],
                                    galleryData: this.populateMedia("assets/img/cars/model/nissan/sentra/2018/sentra", 10)
                                }
                            ]
                        }
                    ]
                },
                suvType: {
                    buttonText: "Ver modelos",
                    classContainer: "app-section-car-type-brand-list-g-container",
                    inAnimation: "fadeIn",
                    outAnimation: "fadeOut",
                    data: [
                        {
                            brandName: "Mitsubishi",
                            brandSlogan: "Drive your Ambition",
                            brandIcon: require("@/assets/img/cars/brand/mitsubishi.svg"),
                            brandIconColor: "#eaedf1",
                            modelList: [
                                {
                                    id: `uuid${(Math.random() + 1).toString(36).substring(7)}`,
                                    icon: require("@/assets/img/cars/model/mitsubishi/outlander001.jpg"),
                                    feature: [
                                        {text: "Modelo", value: "Outlander", icon: "fas fa-car"},
                                        {text: "Año", value: "2015", icon: "fas fa-calendar"},
                                        {text: "Color", value: "Negro", icon: "fas fa-palette"},
                                        {text: "Cilindraje", value: "2,400cc", icon: "fas fa-car-side"},
                                        {text: "Potencia", value: "166 hp", icon: "fas fa-cogs"},
                                        {text: "Transmisión", value: "Automática", icon: "fas fa-arrows-alt-v"}
                                    ],
                                    galleryData: this.populateMedia("assets/img/cars/model/mitsubishi/outlander/2015/outlander", 9)
                                }
                            ]
                        }
                    ]
                }
            }
        }
    },
    methods:{ 
        populateMedia(prefix, total){
            let media = [];
            for (let index = 0; index < total; index++) media.push({
                index, position: index + 1, source: require(`@/${prefix}${index + 1}.jpeg`)
            })
            return media;
        },
        showSedanCarBrandList(){
            if(this.showSedanBrandList){
                animateCSS(`.${this.carBrandListProps.sedanType.classContainer}`, this.carBrandListProps.sedanType.outAnimation).then(() => {
                    this.showSedanBrandList = !this.showSedanBrandList
                    this.carBrandListProps.sedanType.buttonText = "Ver modelos";
                });
            }else {
                this.showSedanBrandList = !this.showSedanBrandList;
                this.carBrandListProps.sedanType.buttonText = "Contraer";
            }
        },
        showSuvCarBrandList(){
            if(this.showSuvBrandList){
                animateCSS(`.${this.carBrandListProps.suvType.classContainer}`, this.carBrandListProps.suvType.outAnimation).then(() => {
                    this.showSuvBrandList = !this.showSuvBrandList
                    this.carBrandListProps.suvType.buttonText = "Ver modelos";
                });
            }else {
                this.showSuvBrandList = !this.showSuvBrandList;
                this.carBrandListProps.suvType.buttonText = "Contraer";
            }
        }
    }
}
</script>

<style scoped>
/** BUTTON:SEE MODELS */
.app-section-car-type-card-button-model{
    backface-visibility: hidden;
    border-radius: 0.795rem;
    border-style: solid;
    border-width: .15rem;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 1.125rem;
    font-weight: 700;
    letter-spacing: -.01em;
    line-height: 1.3;
    padding: .875rem 1.125rem;
    position: relative;
    text-align: left;
    text-decoration: none;
    transform: translateZ(0) scale(1);
    transition: transform .2s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}
.app-section-car-type-card-button-model:not(:disabled):hover {
  transform: scale(1.05);
}
.app-section-car-type-card-button-model:not(:disabled):hover:active {
  transform: scale(1.05) translateY(.125rem);
}
.app-section-car-type-card-button-model:focus {
  outline: 0 solid transparent;
}
.app-section-car-type-card-button-model:focus:before {
  content: "";
  left: calc(-1*.375rem);
  pointer-events: none;
  position: absolute;
  top: calc(-1*.375rem);
  transition: border-radius;
  user-select: none;
}
.app-section-car-type-card-button-model:focus:not(:focus-visible) {
  outline: 0 solid transparent;
}
.app-section-car-type-card-button-model:focus:not(:focus-visible):before {
  border-width: 0;
}
.app-section-car-type-card-button-model:not(:disabled):active {
  transform: translateY(.125rem);
}

/* style:unique */
/** SEDA */
.app-section-car-type-card-title-sedan{
    color:#3d56b2;
}
.app-section-car-type-card-sedan{
    border-top: 15px solid #3d56b2;
}
.app-section-car-type-card-button-model-sedan{
    background: #1E3163;
    border-color: #1E3163;
    color: #fff;
}
/** SUV */
.app-section-car-type-card-title-suv{
    color:#57CC99;
}
.app-section-car-type-card-suv{
    border-top: 15px solid #57CC99;
}
.app-section-car-type-card-button-model-suv{
    background: #47896c;
    border-color: #47896c;
    color: #fff;
}

/** CARD:CAR TYPE */
.app-section-car-type-card-container{
    text-decoration: none;
    padding: 0 9%;
    display: block;
    color: #000;
}
.app-section-car-type-card-inner-container{
    padding: 2.5rem;
    border-radius: 24px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.06);
    margin-top: 10em;
    font-size: 19px;
    text-align: left;
    margin: auto;
}
.app-section-car-type-card-title{
    margin-bottom: 0;
    font-size: 45px;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 600;
}
.app-section-car-type-card-desc{
    opacity: .8;
    color: #333;
    margin-top: 10px;
    margin-bottom: 1.3em;
}
.app-section-car-type-card-inner-container img{
    position: absolute;
    top: 14%;
    right: -37%;
    width: 95%;
    height: 95%;
    transform: rotate(-0deg);
    transition: all .3s;
}
.app-section-car-type-card-inner-container:hover img{
    top: 10%;
    right: -35%;
    transform: scale(1.1) rotate(-0deg);
}
@media screen and (min-width: 0px) and (max-width: 1010px){
    .app-section-car-type-card-inner-container{
        margin-top: 2em;
        min-height: 300px;
        border-radius: 0;
    }
    .app-section-car-type-card-inner-container img{
        top: initial;
        bottom: -14%;
        right: -5%;
        width: 45%;
        height: 45%;
    }
    .app-section-car-type-card-inner-container:hover img{
        top: initial;
        bottom: -10%;
        right: -5%;
        transform: scale(1.1) rotate(-0deg);
    }
}
/** style:unique */
/** SEDAN */
.app-section-car-type-card-inner-container-sedan{
    background: #A2DBFA;
}
.app-section-car-type-card-title-sedan{
    color: #1E3163;
}
/** SUV */
.app-section-car-type-card-inner-container-suv{
    background: #b3e9ba;
}
.app-section-car-type-card-title-suv{
    color: #47896c;
}
</style>