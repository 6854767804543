<template>
    <div :id="id" class="app-section-requirements-container p-2 mt-5 mb-5">
        <div class="section-divider"></div>
        <div class="app-section-requirements-inner-container">
            <SectionTitle 
                title="Conoce los requisitos" 
                subtitle="El inicio de tu experiencia..."
                hPosition="right" 
            />
            <section id="cd-timeline" class="cd-container">
                <div v-for="(item, i) in contentPayload" :key="i" class="cd-timeline-block">
                    <div class="cd-timeline-img" :style="`background: ${item.iconBgColor}`">
                        <span><i :class="item.icon"></i></span>
                    </div>
                    <div class="cd-timeline-content">
                        <h2 class="font-ptsans">{{item.title}}</h2>
                        <p class="font-montserrat">{{item.description}}</p>
                        <span class="cd-number font-montserrat">{{i + 1}}</span>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import SectionTitle from './app/SectionTitle.vue';

export default {
    name: 'SectionRequirements',
    components: {
        SectionTitle
    },
    data(){
        return{
            contentPayload: [
                { title: "Documento de identidad", description: "Es indispensable ser mayor de edad, por lo tanto neceistaremos tu DUI para los requisitos posteriores", icon: "far fa-id-card", iconBgColor: "#422B72" },
                { title: "Número de NIT", description: "Al igual que el requisito anterior, este documento es indispensable para el trámite de alquiler", icon: "far fa-id-card", iconBgColor: "#266D98" },
                { title: "Licencia de conducir", description: "Necesitas tener licencia de conducir vigente", icon: "fas fa-car", iconBgColor: "#3CB29A" },
                { title: "Tu firma", description: "Para que todo sea transparante, es requisito firmar nuestro contrato de alquiler", icon: "fas fa-signature", iconBgColor: "#79B4B7" },
                { title: "Información general", description: "Como último requisito, es necesario que nos proporciones un recibo de agua o luz de tu lugar de residencia actual", icon: "fas fa-receipt", iconBgColor: "#8CA1A5" },
                //{ title: "Requisito 5", description: "Descripción del contenido", icon: "far fa-id-card", iconBgColor: "#8CA1A5" },
                //{ title: "Requisito 6", description: "Descripción del contenido", icon: "far fa-id-card", iconBgColor: "#9D9D9D" }
            ]
        }
    },
    props: {
        id: String
    },
    mounted(){ }
}
</script>

<style scoped>
h2{
    font-weight: 600;
}
img {
    max-width: 100%;
}
.cd-container {
    width: 90%;
    max-width: 1170px;
    margin: 0 auto;
}
.cd-container::after {
    content: "";
    display: table;
    clear: both;
}
header {
    height: 200px;
    line-height: 200px;
    text-align: center;
    background: #303e49;
}
header h1 {
    color: #ffffff;
    font-size: 18px;
    font-size: 1.125rem;
}
@media only screen and (min-width: 1170px) {
  header {
        height: 300px;
        line-height: 300px;
  }
  header h1 {
        font-size: 24px;
        font-size: 1.5rem;
  }
}

#cd-timeline {
    position: relative;
    padding: 2em 0;
    margin-top: 2em;
    margin-bottom: 2em;
}
#cd-timeline::before {
    content: "";
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 4px;
    background: #d7e4ed;
}
@media only screen and (min-width: 1170px) {
  #cd-timeline {
        margin-top: 3em;
        margin-bottom: 3em;
  }
  #cd-timeline::before {
        left: 50%;
        margin-left: -2px;
  }
}

.cd-timeline-block {
    position: relative;
    margin: 2em 0;
}
.cd-timeline-block::after {
    clear: both;
    content: "";
    display: table;
}
.cd-timeline-block:first-child {
    margin-top: 0;
}
.cd-timeline-block:last-child {
    margin-bottom: 0;
}
@media only screen and (min-width: 1170px) {
  .cd-timeline-block {
        margin: 4em 0;
  }
  .cd-timeline-block:first-child {
        margin-top: 0;
  }
  .cd-timeline-block:last-child {
        margin-bottom: 0;
  }
}

.cd-timeline-img {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 0 0 4px #ffffff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}
.cd-timeline-img span {
    color: #ffffff;
    font-size: 23px;
}
@media only screen and (min-width: 1170px) {
  .cd-timeline-img {
        width: 60px;
        height: 60px;
        left: 50%;
        margin-left: -30px;
        -webkit-transform: translateZ(0);
        -webkit-backface-visibility: hidden;
  }
  .cssanimations .cd-timeline-img.is-hidden {
        visibility: hidden;
  }
  .cssanimations .cd-timeline-img.bounce-in {
        visibility: visible;
        -webkit-animation: cd-bounce-1 0.6s;
        -moz-animation: cd-bounce-1 0.6s;
        animation: cd-bounce-1 0.6s;
  }
}

@-webkit-keyframes cd-bounce-1 {
  0% {
        opacity: 0;
        -webkit-transform: scale(0.5);
  }
  60% {
        opacity: 1;
        -webkit-transform: scale(1.2);
  }
  100% {
        -webkit-transform: scale(1);
  }
}
@-moz-keyframes cd-bounce-1 {
  0% {
        opacity: 0;
        -moz-transform: scale(0.5);
  }
  60% {
        opacity: 1;
        -moz-transform: scale(1.2);
  }
  100% {
        -moz-transform: scale(1);
  }
}
@keyframes cd-bounce-1 {
  0% {
        opacity: 0;
        -webkit-transform: scale(0.5);
        -moz-transform: scale(0.5);
        -ms-transform: scale(0.5);
        -o-transform: scale(0.5);
        transform: scale(0.5);
  }
  60% {
        opacity: 1;
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
        transform: scale(1.2);
  }
  100% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
  }
}
.cd-timeline-content {
    position: relative;
    margin-left: 60px;
    background: #ffffff;
    border-radius: 0.25em;
    padding: 1em;
    box-shadow: 0 3px 0 #d7e4ed;
}
.cd-timeline-content::after {
    clear: both;
    content: "";
    display: table;
}
.cd-timeline-content h2 {
    color: #303e49;
}
.cd-number{
    font-weight: 600;
}
.cd-timeline-content p, .cd-timeline-content .cd-number {
    font-size: 13px;
    font-size: 0.8125rem;
}
.cd-timeline-content .cd-number {
    display: inline-block;
}
.cd-timeline-content p {
    margin: 1em 0;
    line-height: 1.6;
}
.cd-timeline-content .cd-number {
    float: left;
    padding: 0.8em 0;
    opacity: 0.7;
}
.cd-timeline-content::before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #ffffff;
}
@media only screen and (min-width: 768px) {
  .cd-timeline-content h2 {
        font-size: 20px;
        font-size: 1.25rem;
  }
  .cd-timeline-content p {
        font-size: 16px;
        font-size: 1rem;
  }
  .cd-timeline-content .cd-number {
        font-size: 14px;
        font-size: 0.875rem;
        font-weight: 600;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-timeline-content {
        margin-left: 0;
        padding: 1.6em;
        width: 45%;
  }
  .cd-timeline-content::before {
        top: 24px;
        left: 100%;
        border-color: transparent;
        border-left-color: #ffffff;
  }
  .cd-timeline-content .cd-number {
        position: absolute;
        width: 100%;
        left: 122%;
        top: 6px;
        font-size: 16px;
        font-size: 1rem;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content {
        float: right;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
        top: 24px;
        left: auto;
        right: 100%;
        border-color: transparent;
        border-right-color: #ffffff;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-number {
        left: auto;
        right: 122%;
        text-align: right;
  }
  .cssanimations .cd-timeline-content.is-hidden {
        visibility: hidden;
  }
  .cssanimations .cd-timeline-content.bounce-in {
        visibility: visible;
        -webkit-animation: cd-bounce-2 0.6s;
        -moz-animation: cd-bounce-2 0.6s;
        animation: cd-bounce-2 0.6s;
  }
}

@media only screen and (min-width: 1170px) {
  .cssanimations .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
        -webkit-animation: cd-bounce-2-inverse 0.6s;
        -moz-animation: cd-bounce-2-inverse 0.6s;
        animation: cd-bounce-2-inverse 0.6s;
  }
}
@-webkit-keyframes cd-bounce-2 {
  0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
  }
  60% {
        opacity: 1;
        -webkit-transform: translateX(20px);
  }
  100% {
        -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2 {
  0% {
        opacity: 0;
        -moz-transform: translateX(-100px);
  }
  60% {
        opacity: 1;
        -moz-transform: translateX(20px);
  }
  100% {
        -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2 {
  0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
        -moz-transform: translateX(-100px);
        -ms-transform: translateX(-100px);
        -o-transform: translateX(-100px);
        transform: translateX(-100px);
  }
  60% {
        opacity: 1;
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        -ms-transform: translateX(20px);
        -o-transform: translateX(20px);
        transform: translateX(20px);
  }
  100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
  }
}
@-webkit-keyframes cd-bounce-2-inverse {
  0% {
        opacity: 0;
        -webkit-transform: translateX(100px);
  }
  60% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
  }
  100% {
        -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2-inverse {
  0% {
        opacity: 0;
        -moz-transform: translateX(100px);
  }
  60% {
        opacity: 1;
        -moz-transform: translateX(-20px);
  }
  100% {
        -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2-inverse {
  0% {
        opacity: 0;
        -webkit-transform: translateX(100px);
        -moz-transform: translateX(100px);
        -ms-transform: translateX(100px);
        -o-transform: translateX(100px);
        transform: translateX(100px);
  }
  60% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px);
  }
  100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
  }
}
</style>