<template>
  <div class="p-1 app-g-container">
    <AppNavbar 
        :iconLogo="require('@/assets/logo_.png')"
        :data="[
            {link:'#home', text: 'Inicio', icon: 'fas fa-home'},
            {link:'#types', text: 'Modelos', icon: 'fas fa-car'},
            {link:'#requirements', text: 'Requisitos', icon: 'fas fa-align-left'},
            {link:'#contact', text: 'Contáctanos', icon: 'fas fa-envelope'},
            {link:'#aboutus', text: 'Sobre nosotros', icon: 'fas fa-award'}
        ]" 
    />
    <div id="home" class="section-divider"></div>
    <SectionHome id="homePage" />
    <SectionCarType id="types" />
    <SectionRequirements id="requirements" />
    <SectionContact id="contact" />
    <SectionAboutUs id="aboutus" />
    <AppFooter />
  </div>
</template>

<script>
import AppNavbar from './components/app/Navbar.vue';
import SectionHome from './components/Home.vue';
import SectionCarType from './components/CarType.vue';
import SectionRequirements from './components/Requirements.vue';
import SectionContact from './components/Contact.vue';
import SectionAboutUs from './components/AboutUs.vue';
import AppFooter from './components/app/Footer.vue';

export default {
  name: 'App',
  components: {
    AppNavbar,
    SectionHome,
    SectionCarType,
    SectionRequirements,
    SectionContact,
    SectionAboutUs,
    AppFooter
  },
  mounted() { },
  setup() { },
  methods: { }
}
</script>
  
<style>
body{
  background: #eee;
}
.app-g-container{
  position: relative;
  overflow: hidden;
}
</style>
