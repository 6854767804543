<template>
    <nav class="navbar navbar-expand fixed-top pt-3 pb-3 app-navbar-g-container">
        <div class="container-fluid">
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <div class="d-flex align-items-center justify-content-center navbar-brand-logo-container">
                    <!--<img :src="iconLogo" height="25" alt="" loading="lazy" />-->
                </div>
                <ul class="navbar-nav me-auto mb-lg-0 app-navbar-items-container">
                    <li v-for="item in data" :key="item.id" class="nav-item font-montserrat">
                        <a :href="item.link" class="nav-link app-navbar-link">
                            <i :class="item.icon"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import $ from "jquery";

export default {
    name: 'AppNavbar',
    props: {
        iconLogo: {
            type: String,
            default: ""
        },
        data: {
            type: Array,
            default: () => ([])
        }
    },
    mounted () {
        $(document).scroll(function () {
            var $nav = $(".fixed-top");
            $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
        });
    }
}
</script>

<style scoped>
.app-navbar-g-container.scrolled{
  background-color: #262626 !important;
  box-shadow: 0 1px 3px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.06);
  transition: background-color 200ms linear;
}
.app-navbar-g-container{
    height: 80px;
}
.navbar-brand-logo-container{
    width: 50px;
}
.app-navbar-items-container{
    font-size: 17px;
}
.app-navbar-icon-item{
    font-size: 20px;
    margin-right: 10px;
}
.app-navbar-link{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.app-navbar-items-container{
    margin: 0;
    padding: 0;
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.app-navbar-items-container li {
    list-style: none;
    margin: 0 15px;
}
.app-navbar-items-container li a {
    position: relative; 
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 63px;
    background: #333;
    border-radius: 50%;
    transition: .5s;
}
.app-navbar-items-container li a::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #FFE459;
    transition: .5s;
    transform: scale(.9);
    z-index: -1;
}
.app-navbar-items-container li a:hover::before {
    transform: scale(1.1);
    box-shadow: 0 0 15px #FFE459;
}
.app-navbar-items-container li a:hover {
    color: #FFE459;
    box-shadow: 0 0 5px #FFE459;
    text-shadow: 0 0 5px #FFE459;
}
@media screen and (min-width: 0px) and (max-width: 650px){
    .app-navbar-items-container li a {
        width: 40px;
        height: 40px;
    }
}
</style>