<template>
    <div :id="id" :class="`mb-5 ${classContainer}`">
        <div class="section-divider"></div>
        <section>
            <h1 class="font-ptsans">{{payload.title}}</h1>
            <h2 class="font-montserrat">{{payload.subtitle}}</h2>
            <details v-for="item in payload.data" :key="item.id">
                <summary>
                    <div>
                        <span :style="`background-color: ${item.brandIconColor};`">
                            <img :src="item.brandIcon" style="width: 50px; height: 50px;" />
                        </span>
                        <h3>
                            <strong class="font-ptsans">{{item.brandName}}</strong>
                            <small class="font-montserrat">{{item.brandSlogan}}</small>
                        </h3>
                        <span class="font-montserrat model-counter">{{item.modelList.length}}</span>
                    </div>
                </summary>
                <div>
                    <dl v-for="model in item.modelList" :key="model.id" class="pb-4">
                        <div class="col-sm-12 col-md-3 col-lg-4 model-image-container">
                            <img :src="model.icon" class="img-fluid model-image" />
                        </div>
                        <div class="col-sm-12 col-md-9 col-lg-8 feature-container">
                            <div v-for="feature in model.feature" :key="feature.id" class="model-feature">
                                <dt class="font-ptsans feature-text"><i :class="feature.icon"></i> {{feature.text}}</dt>
                                <dd class="font-montserrat">{{feature.value}}</dd>
                            </div>
                        </div>
                        <AppGalleryPopup 
                            v-if="model.galleryData.length"
                            :id="model.id" 
                            buttonText="+ Fotos"
                            sectionText="Galería"
                            :data="model.galleryData"
                        />
                    </dl>
                </div>
            </details>
        </section>
    </div>
</template>

<script>
import AppGalleryPopup from '../components/app/GalleryPopup.vue';
import { animateCSS } from '../assets/js/utilities.js';

let uuid = 0;

export default {
    name: 'SectionCarTypeBrandList',
    components: {
        AppGalleryPopup
    },
    props: {
        id: String,
        animate: Boolean,
        inAnimation: String,
        classContainer: String,
        payload:{
            type: Object,
            default: () => ({})
        }
    },
    data(){ },
    beforeCreate() {
        this.uuid = uuid.toString();
        uuid += 1;
    },
    mounted(){
        if(this.animate) animateCSS(`.${this.classContainer}`, this.inAnimation);
    },
    methods: { }
}
</script>

<style scoped>
section {
    width: 100%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 1px 3px rgb(0 0 0 / 6%), 0 1px 2px rgb(0 0 0 / 6%);
    background: #fff;
    padding: 15px 0px 0px 0px;
    border-radius: 10px 10px 0px 0px;
}
section + section {
    margin-top: 2.5em;
}
h1 {
    font-weight: 700;
    line-height: 1.125;
    font-size: clamp(1.5rem, 2.5vw, 2.5rem);
    margin: 0px 20px;
    color: #A80C1E;
}
h2 {
    margin-top: 0.25em;
    color: #999;
    font-size: clamp(1.125rem, 2.5vw, 1.25rem);
    margin: 0px 20px;
}
h2 + * {
    margin-top: 1.5em;
}
summary {
    background-color: #fff;
    position: relative;
    cursor: pointer;
    padding: 1em 0.5em;
    list-style: none;
}
summary::-webkit-details-marker {
    display: none;
}
summary:hover {
    background-color: #f2f5f9;
}
summary div {
    display: flex;
    align-items: center;
}
summary h3 {
    display: flex;
    flex-direction: column;
}
summary small {
    color: #999;
    font-size: 16px;
}
summary strong {
    font-weight: 700;
    font-size: 30px;
}
summary span:first-child {
    width: 4rem;
    height: 4rem;
    border-radius: 10px;
    background-color: #f3e1e1;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    margin-right: 1.25em;
}
summary span:first-child svg {
    width: 2.25rem;
    height: 2.25rem;
}
summary span:last-child {
    font-weight: 700;
    margin-left: auto;
    padding: 3px 10px;
    background: #d42d40;
    color: #fff;
    border-radius: 25px;
}
summary:focus {
    outline: none;
}
summary .plus {
    color: #289672;
}

details {
    border-bottom: 1px solid #b5bfd9;
}
details[open] {
    box-shadow: -3px 0 0 #b5bfd9;
}
details:first-of-type {
    border-top: 1px solid #b5bfd9;
}
details > div {
    padding: 2em 2em 0;
    font-size: 0.875em;
}

dl {
    display: flex;
    flex-wrap: wrap;
}
dl dt {
    font-weight: 700;
}
dd{
    font-size: 16px;
}
.model-image-container{
    padding: 0px 20px;
}
.model-image{
    border-radius: 10px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 26%), 0 1px 12px rgb(0 0 0 / 6%);
    margin-bottom: 10px;
}
.feature-container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.model-feature {
    width: 120px;
    margin-bottom: 10px;
    display: inline-block;
}
.feature-text{
    color: #393e46;
    font-size: 17px;
}
</style>