<template>
    <div :id="id" class="app-section-aboutus-container p-2 mt-5 mb-5">
        <div class="section-divider"></div>
        <div class="app-section-aboutus-inner-container">
            <SectionTitle 
                title="¿Quienes somos?" 
                subtitle="Marcando una trayectoria"
                hPosition="right" 
            />
            <div class="container-content container-fluid main-container">
                <div class="row">
                    <div class="col-xs-12 col-sm-3 container-icon">
                        <span class="app-logo-face">
                            <i class="fas fa-award"></i>
                        </span>
                    </div>
                <div class="col-xs-12 col-sm-9">
                    <div class="section text-justify">
                        <p class="font-montserrat">Leo Rent A Car se distingue por atender las necesidades de desplazamiento con servicios de calidad, precios competitivos, y sobre todo vehículos en óptimas condiciones, para hacer de su viaje una experiencia inolvidable y segura.</p>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SectionTitle from './app/SectionTitle.vue';

export default {
    name: 'SectionAboutUs',
    components: {
        SectionTitle
    },
    data(){
        return{
          appTitle: document.title
        }
     },
    props: {
        id: String
    },
    mounted(){ }
}
</script>

<style scoped>
.container-content {
    font-family: "El Messiri", "BenchNine", "Patua One", sans-serif;
    background-color: #d0d0d0;
    color: #eeeeee;
    padding: 15px 20px;
    margin-top: 80px;
    border-radius: 15px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 6%), 0 1px 2px rgb(0 0 0 / 6%);
}
.app-logo-face{
    font-size: 80px;
    color: #212121;
}
.main-container{
    max-width: 580px;
}
.section{
    background-color: #eeeeee   ;
    padding: 20px;
    margin-bottom: 50px;
    border-radius: 6px;
    color: #3e2723 ;
}
.container-icon{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media(max-width:700px){
  body{
    margin:0;
  }
  #app-logo-face{
    margin:auto;
    margin-bottom:5%;
  }
  li{
    margin:0 0 20px 0;
  }
  ul{
    margin:0 0 0 -40px;
    list-style-position:inside;
  }
}

@media(min-width:700px) and (max-width:1100px){
  body{
    margin: 5%;
  }  
  #app-logo-face{
    margin:auto;
    margin-bottom:5%;
  }
}

</style>