<template>
    <h1 :class="`font-montserrat app-section-car-type-title content-${hPosition}`">
        <strong class="font-ptsans">{{ title }}</strong>{{ subtitle }}
    </h1>
</template>

<script>
export default {
  name: 'AppSectionTitle',
  props: {
    title: String,
    subtitle: String,
    hPosition: {
        type: String,
        default: "left"
    }
  }
}
</script>

<style scoped>
.app-section-car-type-inner-container{
    margin-top: 50px;
}
.app-section-car-type-title{
    color:#666666;
    font-size:30px;
    line-height:1em;
}
.app-section-car-type-title strong{
    color:#A80C1E;
    display:block;
    font-size:85px;
    letter-spacing:-3.5px;
    line-height:1em;
}
.content-left{
    text-align: left;
    border-left: 9px solid #A80C1E;
    padding-left: 15px;
    margin:0 0 0 40px;
}
.content-right{
    text-align: right;
    border-right: 9px solid #A80C1E;
    padding-right: 15px;
    margin:0 40px 0 0;
}
@media screen and (min-width: 0px) and (max-width: 1010px){
    .app-section-car-type-title strong{
        font-size:65px;
    }
    .app-section-car-type-title{
        font-size:20px;
    }
}
</style>