<template>
    <div :id="id" class="app-section-contact-container p-2 mt-5 mb-5">
        <div class="section-divider"></div>
        <div class="app-section-contact-inner-container">
            <SectionTitle 
                title="Alquila tu favorito" 
                subtitle="Contáctanos por medio de nuestras redes sociales"
            />
        </div>
        <div class="app-contact-social-container">
            <contact-social>
                <div class="app-contact-social-social-container wrapper">
                    <a 
                        href="https://www.instagram.com/leorentacarsv/" 
                        class="app-contact-social-social-instagram icon instagram"
                        target="_blank">
                        <div class="tooltip">Instagram</div>
                        <span><ion-icon name="logo-instagram"></ion-icon></span>
                    </a>
                    <a 
                        href="https://www.facebook.com/LeoRentaCarsv/" 
                        class="app-contact-social-social-facebook icon facebook"
                        target="_blank">
                        <div class="tooltip">Facebook</div>
                        <span><ion-icon name="logo-facebook"></ion-icon></span>
                    </a>
                    <a 
                        href="https://api.whatsapp.com/send?phone=50375887913&Text=Buen%20d%C3%ADa%2C%20estoy%20interesado%20en%20alquilar%20un%20carro" 
                        class="app-contact-social-social-whatsapp icon whatsapp" 
                        target="_blank">
                        <div class="tooltip">WhatsApp</div>
                        <span><ion-icon name="logo-whatsapp"></ion-icon></span>
                    </a>
                    <a 
                        href="mailto:leo.rentacar.sv@gmail.com" 
                        class="app-contact-social-social-email icon email"
                        >
                        <div class="tooltip tooltip-social-email">leo.rentacar.sv@gmail.com</div>
                        <span><ion-icon name="mail-outline"></ion-icon></span>
                    </a>
                </div>
            </contact-social>
        </div>
    </div>
</template>

<script>
import SectionTitle from './app/SectionTitle.vue';
import AppContactForm from './app/ContactForm.vue';

export default {
    name: 'SectionContact',
    components: {
        SectionTitle,
        //AppContactForm
    },
    props: {
        id: String
    }
}
</script>

<style scoped>
.app-contact-social-container {
    padding: 85px 0 0 0;
    color:#4b4c4d;
}
.app-contact-social-container .app-contact-social-social-container {
    text-align:center;
    padding-bottom:25px;
}
.app-contact-social-container .app-contact-social-social-container > a {
    font-size:40px;
    width:80px;
    height:80px;
    line-height:85px;
    display:inline-block;
    text-align:center;
    border-radius:50%;
    border:1px solid #ccc;
    margin:0 8px;
    opacity:0.75;
    background: #fff;
}
.app-contact-social-container .app-contact-social-social-container > a:hover {
    opacity:0.9;
}
.app-contact-social-container .app-contact-social-copyright {
    margin-top:15px;
    text-align:center;
    font-size:18px;
    color:#aaa;
    margin-bottom:0;
}
.app-contact-social-social-instagram{
    color: #E1306C;
}
.app-contact-social-social-facebook{
    color: #1877F2;
}
.app-contact-social-social-whatsapp{
    color: #25d366;
}
.app-contact-social-social-email{
    color: #EA4335;
}
.wrapper .icon {
    position: relative;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper .tooltip {
    position: absolute;
    top: 0;
    font-size: 14px;
    background-color: #ffffff;
    color: #ffffff;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper .tooltip::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background-color: #ffffff;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper .icon:hover .tooltip {
    top: -45px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}
.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}
.wrapper .facebook:hover,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip::before {
    background-color: #1877F2;
    color: #ffffff;
}
.wrapper .whatsapp:hover,
.wrapper .whatsapp:hover .tooltip,
.wrapper .whatsapp:hover .tooltip::before {
    background-color: #25d366;
    color: #ffffff;
}
.wrapper .instagram:hover,
.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip::before {
    background-color: #E1306C;
    color: #ffffff;
}
.wrapper .email:hover,
.wrapper .email:hover .tooltip,
.wrapper .email:hover .tooltip::before {
    background-color: #de463b;
    color: #ffffff;
}
.tooltip-social-email{
    left: -50px;
}
@media screen and (min-width: 0px) and (max-width: 650px){
    .app-contact-social-container .app-contact-social-social-container > a {
        width: 65px;
        height: 65px;
    }
}
</style>