<template>
    <div :id="id" class="container">
        <span class="line-1">
            <div id="img-crop">
                <div id="upload">
                    <div class="block">
                    <div class="stage">
                        <label id="filedrag">
                            <img class="img-fluid home-app-logo" :src="require('@/assets/app_logo.jpeg')" />
                        </label>
                    </div>
                    </div>
                </div>
            </div>
        </span>
        <span class="line-2">LEO RENT A CAR</span>
        <span class="line-3 font-montserrat">Líderes en servicio de alquiler de vehículos</span>
        <span class="line-5"></span>
        <span class="line-6">
            <a href="#types" class="button font-ptsans">Explorar</a>
        </span>
    </div>
</template>

<script>
export default {
    name: 'SectionHome',
    props: {
        id: {
            type: String,
            default: "appHome"
        }
    },
    mounted(){ }
}
</script>

<style scoped>
.container {
    font-family: "Pacifico", cursive;
    font-weight: 400;
    position: relative;
    margin: 20px auto;
    width: 100%;
    font-size: 30px;
    text-align: center;
    opacity: 0.8;
}
span {
    display: block;
}
.line-1 {
    position: relative;
}
.line-2 {
    font-family: "Changa One", sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 90px;
    letter-spacing: 15px;
    margin: 0 0;
    line-height: 60px;
    letter-spacing: 1px;
}
.line-3 {
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    margin: 70px 0 0;
    position: relative;
    letter-spacing: 6px;
}
.line-4 {
    width: 100%;
    position: relative;
    font-size: 18px;
    margin: 50px 0 0;
}
.line-5 {
    margin: 20px 0 50px;
    position: relative;
    text-align: center;
    margin: 50px auto;
    display: block;
    font-size: 20px;
}
.line-5 span {
    text-decoration: none;
    display: inline-block;
    text-align: left;
    color: #222;
}
.line-5 span:nth-child(1) {
    text-align: right;
}
.line-5 span:nth-child(1):after {
    content: "";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    position: relative;
    font-size: 30px;
    text-align: center;
    width: 90%;
    top: 4px;
    margin: 0;
    padding: 0 20px;
}
.line-6 {
    font-family: "PT Sans", sans-serif;
    font-size: 16px;
    letter-spacing: 5px;
}
.line-6:before, .line-6:after {
    content: "";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    position: relative;
    font-size: 16px;
    margin: 0 10px;
    top: 2px;
}
.button{
    backface-visibility: hidden;
    cursor: pointer;
    font-size: 1.125rem;
    font-weight: 700;
    letter-spacing: -.01em;
    line-height: 1.3;
    position: relative;
    transform: translateZ(0) scale(1);
    transition: transform .2s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    background-color: #262626;
    border: 2px solid #262626;
    color: #ffffff;
    display: inline-block;
    margin: 0;
    padding: 12px 32px 14px 32px;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 10px;
}
.button:not(:disabled):hover {
    transform: scale(1.05);
}
.button:not(:disabled):hover:active {
    transform: scale(1.05) translateY(.125rem);
}
.button:focus {
    outline: 0 solid transparent;
}
.button:focus:before {
    content: "";
    left: calc(-1*.375rem);
    pointer-events: none;
    position: absolute;
    top: calc(-1*.375rem);
    transition: border-radius;
    user-select: none;
}
.button:focus:not(:focus-visible) {
    outline: 0 solid transparent;
}
.button:focus:not(:focus-visible):before {
    border-width: 0;
}
.button:not(:disabled):active {
    transform: translateY(.125rem);
}
.button:hover {
    background-color: #ffffff;
    color: #262626;
}


#img-crop {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 30px auto;
}
#img-crop .block {
  border: 12px solid #fff;
  border-radius: 1em;
  box-shadow: 0 20px 60px -2px rgba(28, 43, 56, 0.2);
  background-color: #eff3f6;
}
#img-crop .block .stage {
  width: 300px;
  position: relative;
}
#img-crop #upload label#filedrag {
  display: block;
  width: 100%;
  height: 100%;
  background-size: 25% auto;
  transition: background 0.3s ease;
}
.home-app-logo{
    border-radius: 20px;
}
@media screen and (min-width: 0px) and (max-width: 650px){
    #img-crop .block .stage {
        width: 200px;
    }
    .line-2{
        font-size: 85px;
    }
}
</style>
