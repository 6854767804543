<template>
    <div :id="`popup${id}`" class="popup">
        <div class="top-bar">
            <div class="x close-btn" @click="onClickClosePopupEvent()">
                <div class="line1"></div>
                <div class="line2"></div>
            </div>
        </div>
        <button class="arrow-btn left-arrow" @click="onClickLeftArrowEvent()"><img :src="require('@/assets/svg/left-arrow.svg')" alt=""></button>
        <button class="arrow-btn right-arrow" @click="onClickRightArrowEvent()"><img :src="require('@/assets/svg/left-arrow.svg')" alt=""></button>
        <img :id="`largeImage${id}`" src="" class="large-image" alt="">
    </div>
    <div class="gallery-g-container">
        <div :id="id" :class="`gallery-container`" @click="openGallery($event)">
            <div class="from">
                <div class="from-contents">
                    <div class="avatar me"></div>
                    <div class="name font-ptsans"><i class="far fa-images"></i> {{buttonText}}</div>
                </div>
            </div>
            <div class="to">
                <div class="to-contents">
                    <div class="top">
                        <div class="name-large font-ptsans"><i class="far fa-images"></i></div>
                        <div :id="`xTouch${id}`" class="x-touch" @click="closeGallery($event, id)">
                            <div class="x">
                                <div class="line1"></div>
                                <div class="line2"></div>
                            </div>
                        </div>
                    </div>
                    <div :id="`bottom${id}`" class="bottom">
                        <div :id="`main${id}`" class="main">
                            <div class="pictures">
                                <img v-for="(item, i) in data" 
                                    :key="i" 
                                    :src="item.source"
                                    :data-parentId="id"
                                    :data-group="`imgGroup${id}`"
                                    :data-key="`${id}${i + 1}`"
                                    @click="onClickImageEvent(i + 1, item.source, $event.currentTarget)"
                                    alt="" 
                                    class="picture image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppGalleryPopup',
    props: {
        id: String,
        buttonText: String,
        sectionText: String,
        data: {
            type: Array,
            default: () => ([])
        }
    },
    data(){
        return{
            currentKey: 0,
            imagesLoaded: 0,
            oneRow: [1, 4, 7, 10, 13, 16]
        }
     },
    mounted () { 
        const self = this;
        window.addEventListener("resize", function(){
            self.setGalleryHeight(document.getElementById(self.id));
        }, false);
    },
    methods:{
        setGalleryHeight(eContainer){
            if(eContainer instanceof HTMLElement && eContainer.classList.contains("expand")){
                const contentContainerId = `bottom${this.id}`;
                const contentId = `main${this.id}`;
                const picturesHeight = document.getElementById(contentId).offsetHeight;
                eContainer.style.height = `${picturesHeight + 60}px`;
                document.getElementById(contentContainerId).style.height = `${picturesHeight + 60}px`;
            }
        },
        openGallery(e){
            const xTouch = document.getElementById(`xTouch${this.id}`);
            const picImages = document.querySelectorAll(`[data-group="imgGroup${this.id}"]`);
            const eContainer = e.currentTarget;
            eContainer.classList.add('expand');
            xTouch.style.zIndex = 1;
            if((picImages.length + 2) % 3 == 0) {
                picImages[picImages.length - 1].style.width = "100%";
            }
            if((picImages.length + 1) % 3 == 0) {
                picImages[picImages.length - 2].style.width = "50%"; 
                picImages[picImages.length - 2].style.paddingRight = "1px"
                picImages[picImages.length - 1].style.width = "50%"; 
                picImages[picImages.length - 1].style.paddingLeft = "1px"
            }
            setTimeout((function(scope) {
                scope.setGalleryHeight(eContainer);
            }), 500, this);
        },
        closeGallery(event, id){
            const xTouch = event.currentTarget;
            const eExec = document.getElementById(id);
            eExec.classList.remove('expand');
            eExec.style.height = "32px";
            xTouch.style.zIndex = -1;
            event.stopPropagation();
        },
        onClickImageEvent(i, img, e){
            const popup = document.querySelector(`#popup${this.id}`);
            this.updateImage(img);
            popup.classList.toggle('active');
            this.currentKey = i;
        },
        onClickClosePopupEvent(){
            const popup = document.querySelector(`#popup${this.id}`);
            popup.classList.toggle('active');
        },
        onClickLeftArrowEvent(){
            this.currentKey -= 1;
            const images = [...document.querySelectorAll(`[data-group="imgGroup${this.id}"]`)];
            if(this.currentKey == 0) this.currentKey = images.length;
            var imgPath = document.querySelector(`[data-key="${this.id}${this.currentKey}"]`).getAttribute('src');
            this.updateImage(imgPath);
        },
        onClickRightArrowEvent(){
            this.currentKey += 1;
            const images = [...document.querySelectorAll(`[data-group="imgGroup${this.id}"]`)];
            if(this.currentKey > images.length) this.currentKey = 1;
            var imgPath = document.querySelector(`[data-key="${this.id}${this.currentKey}"]`).getAttribute('src');
            this.updateImage(imgPath);
        },
        updateImage(img){
            let path = img;
            const largeImage = document.querySelector(`#largeImage${this.id}`);
            largeImage.src = path;
        }
    }
}
</script>


<style scoped>
.gallery-g-container{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    width: 100%;
}
.gallery-container {
    display: block;
    margin-left: auto;
    margin-right: auto;
    background: #DEDBDF;
    border-radius: 16px;
    height: 32px;
    overflow: hidden;
    position: relative;
    width: 135px;
    -webkit-tap-highlight-color: transparent;
    transition: width 300ms cubic-bezier(0.4, 0.0, 0.2, 1),
    height 300ms cubic-bezier(0.4, 0.0, 0.2, 1),
    box-shadow 300ms cubic-bezier(0.4, 0.0, 0.2, 1),
    border-radius 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
}
.gallery-container:not(.expand) {
    cursor: pointer;
}
.gallery-container:not(.expand):hover {
    background: #C2C0C2;
}
.from {
    position: absolute;
    transition: opacity 200ms 100ms cubic-bezier(0.0, 0.0, 0.2, 1);
}
.from-contents {
    display: flex;
    flex-direction: row;
    transform-origin: 0 0;
    transition: transform 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
}
.to {
    opacity: 0;
    transition: opacity 100ms cubic-bezier(0.4, 0.0, 1, 1);
}
.to-contents {
    transform: scale(.55);
    transform-origin: 0 0;
    transition: transform 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
}
.avatar {
    border-radius: 12px;
    height: 24px;
    left: 6px;
    position: relative;
    top: 4px;
    width: 24px;
}
.name {
    font-size: 14px;
    line-height: 32px;
    margin-left: 10px;
    font-weight: 600;
}
.top {
    background: #DEDBDF;
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 55px;
    transition: height 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
}
.name-large {
    display: block;
    margin-left: auto;
    font-size: 25px;
    line-height: 70px;
    font-weight: 600;
}
.x-touch {
    align-items: center;
    align-self: center;
    cursor: pointer;
    display: flex;
    height: 50px;
    justify-content: center;
    margin-left: auto;
    width: 50px;
    z-index: -1;
}
.x {
    background: #ffffff;
    border-radius: 10px;
    height: 20px;
    position: relative;
    width: 20px;
}
.x-touch:hover .x {
    background: #F3F1F5;
}
.line1 {
    background: #4B6587;
    height: 12px;
    position: absolute;
    transform: translateX(9px) translateY(4px) rotate(45deg);
    width: 2px;
}
.line2 {
    background: #4B6587;
    height: 12px;
    position: absolute;
    transform: translateX(9px) translateY(4px) rotate(-45deg);
    width: 2px;
}
.bottom {
    background: #F7F6F2;
    color:  #444247;
    font-size: 14px;
}
.row {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 60px;
}
.gallery-container.expand {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 25%), 0 1px 2px rgb(0 0 0 / 6%);
    width: 100%;
}
.expand .from {
    opacity: 0;
    transition: opacity 100ms cubic-bezier(0.4, 0.0, 1, 1);
}
.expand .from-contents {
    transform: scale(1.91);
}
.expand .to {
    opacity: 1;
    transition: opacity 200ms 100ms cubic-bezier(0.0, 0.0, 0.2, 1);
}
.expand .to-contents {
    transform: scale(1);
}
.container {
    margin: 0 auto;
    background-color: #fff;
    max-width: 400px;
    max-height: 650px;
    overflow: hidden;
    box-shadow: 0px 0px 20px #202020;
    margin-top: 1em;
    color: #202020;
}
.pictures {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.picture {
    width: 33%;
    height: auto;
    margin-bottom: 2px;
    cursor: pointer;
}

.popup{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 100%;
    height: 100%;
    max-height: 800px;
    background: #262626;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    overflow: hidden;
    transition: .5s;
    opacity: 0;
    margin-top: 80px;
}
.popup.active{
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
}
.popup.active .close-btn,
.popup.active .large-image,
.popup.active .arrow-btn{
    opacity: 1;
    transition: opacity .5s;
    transition-delay: .5s;
}
.top-bar{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    color: #fff;
    text-align: center;
    line-height: 50px;
    font-weight: 300;
}
.close-btn{
    opacity: 0;
    position: absolute;
    top: 15px;
    right: 20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
}
.arrow-btn{
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px;
    border-radius: 50%;
    border: none;
    background: none;
    cursor: pointer;
}
.left-arrow{
    left: 10px;
}
.right-arrow{
    right: 10px;
    transform: translateY(-50%) rotate(180deg);
}
.arrow-btn:hover{
    background: rgba(0, 0, 0, 0.5);
}
.large-image{
    width: 90%;
    height: 90%;
    object-fit: contain;
    opacity: 0;
}
</style>